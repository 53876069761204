// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-simple-dialog',
//   templateUrl: './simple-dialog.component.html',
//   styleUrls: ['./simple-dialog.component.css']
// })
// export class SimpleDialogComponent implements OnInit {

//   constructor() { }

//   ngOnInit() {
//   }

// }
import { Component,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import 'hammerjs';
@Component({
    selector: 'app-simple-dialog',
    templateUrl: './simple-dialog.component.html',
    styleUrls: ['./simple-dialog.component.css']
  })
// @Component({
//  template: `
//  <h1 mat-dialog-title>Hello There</h1>
//  <div mat-dialog-content>
//  <p>This Is a Simple Dialog</p>
//  </div>
//  <div mat-dialog-actions>
//  <button mat-button (click)="close()">Close</button>
//  </div>
// `
// })
export class SimpleDialogComponent {
  message: string = "";
   htmlContent: string='';
  cancelButtonText = "Cancel"
 constructor( @Inject(MAT_DIALOG_DATA) private data: any,
 public dialogRef: MatDialogRef<SimpleDialogComponent>) { 
  if (data) {
    //alert(data)
    this.message = data.message || this.message;
    //dialogRef.componentInstance.htmlContent = data.message ||this.message;
    // dialogRef.componentInstance.data = data.message || this.message;
    //alert(this.message)
   //dialogRef.componentInstance.htmlContent = this.message;
   
    if (data.buttonText) {
      this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
    }
  }
  this.dialogRef.updateSize('300vw','300vw')
 }
 
 close(): void {
 this.dialogRef.close();
 }
}
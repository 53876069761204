import { Component, OnInit, AfterViewInit,ChangeDetectionStrategy ,ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseapiService } from './baseapi.service'
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import{ Location} from '@angular/common'
import { SimpleDialogComponent } from './simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [Location],
})
export class AppComponent implements OnInit,AfterViewInit {
  simpleDialog: MatDialogRef<SimpleDialogComponent>;
  title = 'cvdelitecss';
  // template: string =`<img src="http://pa1.narvii.com/5722/2c617cd9674417d272084884b61e4bb7dd5f0b15_hq.gif" />`
  template: string =`<img src="/assets/img/giphy.webp" />`
  url;
  isset;
  location: Location;
  constructor(private _ser: BaseapiService, private _r: Router, private _ar: ActivatedRoute,private cd: ChangeDetectorRef,private dialogModel: MatDialog,location: Location) {
   
  }

  ngAfterViewInit(){
    
    this._r.events.subscribe(() => {

      this._ser.isUrlSet.subscribe(url => {
        if(localStorage.getItem("device_id")!= ''){
          this.url='true'
        }else{
          this.url='false'
        }
        
      })
      this.cd.detectChanges();
      
    })
    
  }
  ngOnInit() {
    // alert(this._r.url)
    this.checklogin()
    this.isset = false;
   // this.dialog();
      //   // this._ser.checkLife(localStorage.getItem("sid")).subscribe((res)=>{
      //   //   this.username= res['user']['FullName'];
      //   // });
      // })
      // this._ser.checkLife(localStorage.getItem("sid")).subscribe((res)=>{
      //   this.username= res['user']['FullName'];
   
    // console.log("App = >",this.username)

  }
  checklogin(){
    if(localStorage.getItem("device_id")!= ''){
      this.url='true'
    }else{
      this.url='false'
    }
  }
  dialog() {
    // alert()
    this.simpleDialog = this.dialogModel.open(SimpleDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Save',
          cancel: 'No'
        }
      }
    });
    }
}

import { Component, OnInit } from '@angular/core';
import { BaseapiService } from '../baseapi.service';
import { Router } from '@angular/router'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
title='';
  constructor(private _ser:BaseapiService, private _r:Router
    ) { }
    logout(){
      var con =confirm("Do you want to Logout?")
      // alert()
      if (con === true){
        //alert("gggg")
        localStorage.setItem('access_token','');
        localStorage.setItem('device_id','');
        this._ser.changeUrl('false');
        this._r.navigate(['/login'])
      }else{
        
        // this._r.navigate(['/postservicefeedback']);
        this._ser.changeUrl('true');
        console.log("kkkk")
         this._r.navigate(['/notification'])
      }
      console.log(localStorage.getItem("device_id"))
      // else{
      //   alert("HHHH");
      //   return
      //   // this._r.navigate(['/notification']); 
      //   //alert("Hiii")
      // }
     
     // this.logout();
      }
  ngOnInit() {
    //this.username=localStorage.getItem('username');
    if(localStorage.getItem('app_name')=='com.tatamotors.cvcustomercare'){
     this.title="Commercial Vehicle" ;
    }
    else if(localStorage.getItem('app_name')=='com.ttl.customersocialapp'){
      this.title="Passenger Vehicle" ;
    }
   
    //this.title=localStorage.getItem('username');


  }

}

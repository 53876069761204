import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import 'hammerjs';
@Component({
  selector: 'app-commondialog',
  templateUrl: './commondialog.component.html',
  styleUrls: ['./commondialog.component.css']
})
export class CommondialogComponent {

  message: string = "hhhhhhhhhhh";
  htmlContent: string='';
 cancelButtonText = "Cancel"
constructor( @Inject(MAT_DIALOG_DATA,) private data: any,
public dialogRef: MatDialogRef<CommondialogComponent>,public dialog: MatDialog) { 
 if (data) {
   //alert(data)
   this.message = data.message || this.message;
   //dialogRef.componentInstance.htmlContent = data.message ||this.message;
   // dialogRef.componentInstance.data = data.message || this.message;
   //alert(this.message)
  //dialogRef.componentInstance.htmlContent = this.message;
  
   if (data.buttonText) {
     this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
   }
 }
 this.dialogRef.updateSize('300vw','300vw')
}

close(): void {
this.dialogRef.close();
}


}
